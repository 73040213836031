<template>
  <v-main>
    <v-header
      :headerImage="image"
      :displayOverlay="true"
      :overlayCopy="overlayCopy"
    />
    <container type="bg-dark" id="start">
      <v-row class="align-center text-center" style="padding-bottom:120px; padding-top:200px">
        <v-col :lg="6">
          <div class="bg-light pa-15 text-left">
            <h1>
              We've been delivering successful communications campaigns since
              2010
            </h1>
            <p>
              Now with offices in London and Manchester, Abu Dhabi and Dubai, Q
              Communications has a reputation for big ideas, new insights,
              relentless tenacity and achieving exceptional results for our
              clients across the globe.
            </p>
            <p>
              Committed to consistently producing work that is both original and
              impactful, we have helped develop and position brands and support
              overall business goals, for both start-ups and established
              international companies.
            </p>
            <p>
              Q Communications takes pride in the professionalism and specialist
              skill-sets of its multinational team, who, from conceptualisation
              to execution, produce and deliver dynamic and effective support
              for both B2C and B2B clients.
            </p>
          </div>
        </v-col>
        <v-col :lg="6">
          <v-img
            src="@/assets/images/about-02.jpg"
            class="mt-n10 mt-ml-0 ml-lg-n15"
          />
        </v-col>
      </v-row>
    </container>
    <container type="bg-dark-image background-cover">
      <h1 class="text-center">Our Awards</h1>
      <v-row>
        <v-col :sm="6" :md="4" v-for="(item, id) in awards" :key="id">
          <v-card tile>
            <v-img
              :src="item.image"
              gradient="to bottom, rgba(255,255,255,.5), rgba(255,255,255,.5)"
              class="black--text align-center justify-center"
              aspect-ratio="1"
            >
              <v-card-title
                class="justify-center text-center text-uppercase font-weight-bold"
                style="line-height: 1.2; font-size: 1.2rem; max-width: 400px; margin: auto;"
                >{{ item.title }}<br v-if="item.brand" />{{
                  item.brand
                }}</v-card-title
              >
              <v-card-text class="text-center text-uppercase">{{
                item.award
              }}</v-card-text>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </container>
    <container type="bg-dark">
      <v-row class="align-center" style="padding-top:200px">
        <v-col :md="6">
          <h1>Our Team</h1>
          <p>
            Collectively, our team of specialists have extensive experience of
            both living and working across all five continents. The team is made
            up of ex-journalists, content creators, art directors, PR
            professionals, copy-writers and talent agents, who know how to speak
            the local language and get your story told.
          </p>
          <p>Multilingual team with fluency in 17 different languages:</p>
          <v-row dense>
            <v-col
              class="mb-1"
              :cols="6"
              :lg="4"
              :xl="3"
              v-for="(item, id) in languages"
              :key="id"
            >
              <v-card tile>
                <v-row no-gutters class="justify-center align-center">
                  <v-col class="py-0" :cols="4"
                    ><v-img :src="item.image"
                  /></v-col>
                  <v-col
                    class="py-0 px-1 text-center font-weight-bold text-uppercase"
                    :cols="8"
                    >{{ item.language }}</v-col
                  >
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col :md="6">
          <v-img src="@/assets/images/about-03.jpg" />
        </v-col>
      </v-row>
    </container>
    <container type="bg-dark" id="start">
      <title-and-description title="Join Our Team">
        <p>
          We’re looking for talented PR and Digital maestros to join the Q team! Benefit from working in 
          an environment that encourages growth, where people come together as one team to drive a shared goal.
        </p>
        <p>
          Q Communications is proud to be an equal opportunity work place and welcomes all applicants. We are 
          committed to finding strong, qualified talent to join a team of people who support and inspire one another. 
          If you are interested in pursuing a career with Q Communications, please click on the relevant vacancy link 
          below and follow the steps to apply.
        </p>
        <p>Want to see more of what we do? Then follow us</p>
      </title-and-description>
    </container>
    <instagram-feed />
  </v-main>
</template>

<script>
import vHeader from "@/layout/includes/Header.vue";
import InstagramFeed from "../components/InstagramFeed.vue";

export default {
  name: "About",
  components: {
    vHeader,
    InstagramFeed,
  },
  data() {
    return {
      aboutImage: require("@/assets/images/about-02.jpg"),
      image: require("@/assets/backgrounds/about-1.jpg"),
      overlayCopy: [
        {
          copy: "Benefit from",
          size: "40",
        },
        {
          copy: "Global Expertise",
          size: "40",
        },
        {
          copy: "and Local Knowledge",
          size: "40",
        },
      ],
      awards: [
        {
          title: "Best B2C Campaign on a Budget",
          brand: "Tourism Ireland",
          award: "PRCA MENA AWARDS 2019",
          image: require("@/assets/backgrounds/award-01.jpg"),
        },
        {
          title: "Best Digital Campaign",
          brand: "Voss",
          award: "PRCA MENA AWARDS 2018",
          image: require("@/assets/backgrounds/award-02.jpg"),
        },
        {
          title: "BEST B2C CAMPAIGN",
          brand: "ATKINS",
          award: "PRCA MENA AWARDS 2018",
          image: require("@/assets/backgrounds/award-05.jpg"),
        },
        {
          title: "STRATEGY COMMUNICATIONS",
          brand: "FIRM OF THE YEAR",
          award: "2016",
          image: require("@/assets/backgrounds/award-03.jpg"),
        },
        {
          title: "REPUTATION MANAGEMENT",
          brand: "CONSULTANT OF THE YEAR",
          award: "2016",
          image: require("@/assets/backgrounds/award-06.jpg"),
        },
        {
          title: "BEST MULTIFACETED PR AND MARKETING AGENCY",
          brand: "UAE",
          award: "2016",
          image: require("@/assets/backgrounds/award-04.jpg"),
        }
      ],
      languages: [
        {
          language: "Arabic",
          image: require("@/assets/images/flag-arab.jpg"),
        },
        {
          language: "English",
          image: require("@/assets/images/flag-english.jpg"),
        },
        {
          language: "German",
          image: require("@/assets/images/flag-german.jpg"),
        },
        {
          language: "French",
          image: require("@/assets/images/flag-french.jpg"),
        },
        {
          language: "Russian",
          image: require("@/assets/images/flag-russia.jpg"),
        },
        {
          language: "Spanish",
          image: require("@/assets/images/flag-spanish.jpg"),
        },
        {
          language: "Italian",
          image: require("@/assets/images/flag-italian.jpg"),
        },
        {
          language: "Portugese",
          image: require("@/assets/images/flag-portugese.jpg"),
        },
        {
          language: "Afrikaans",
          image: require("@/assets/images/flag-afrikaans.jpg"),
        },
        {
          language: "Swedish",
          image: require("@/assets/images/flag-swedish.jpg"),
        },
        {
          language: "Tagalog",
          image: require("@/assets/images/flag-tagalog.jpg"),
        },
        {
          language: "Estionian",
          image: require("@/assets/images/flag-estonian.jpg"),
        },
        {
          language: "Finnish",
          image: require("@/assets/images/flag-finnish.jpg"),
        },
        {
          language: "Yoruba",
          image: require("@/assets/images/flag-yoruba.jpg"),
        },
        {
          language: "Urdu",
          image: require("@/assets/images/flag-urdu.jpg"),
        },
        {
          language: "Hindi",
          image: require("@/assets/images/flag-hindi.jpg"),
        },
        {
          language: "Dutch",
          image: require("@/assets/images/flag-dutch.jpg"),
        },
      ],
    };
  },
};
</script>
